import { post } from './axios';
const { recipesTest, recipesTestChoices, recipesQuery, foodsQuery, updateFoodQuery, editRecipeQuery, petUsersQuery, testRecipeQuery, createFoodQuery, delFoodQuery } = require('./query');

// 检测食材
export const testRecipes = (data = {}) => post('/api/graphql/', Object.assign(data, recipesTest()));
export const testRecipesChoices = (data = {}) => post('/api/graphql/', Object.assign(data, recipesTestChoices()));

export const getRecipes = (data = {}) => post('/api/graphql/', Object.assign(data, recipesQuery()));
export const getFoods = (data = {}) => post('/api/graphql/', Object.assign(data, foodsQuery()));
export const updateFood = (data = {}) => post('/api/graphql/', Object.assign(data, updateFoodQuery()));
export const editRecipe = (data = {}) => post('/api/graphql/', Object.assign(data, editRecipeQuery()));
export const getPetUsers = (data = {}) => post('/api/graphql/', Object.assign(data, petUsersQuery()));
export const testRecipe = (data = {}) => post('/api/graphql/', Object.assign(data, testRecipeQuery()));
export const createFood = (data = {}) => post('/api/graphql/', Object.assign(data, createFoodQuery()));
export const delFood = (data = {}) => post('/api/graphql/', Object.assign(data, delFoodQuery()));

export const uploadFiles = (data = {}) => post('/api/graphql/', data);
