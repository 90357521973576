export const recipesTest = () => {
  return {
    query: `mutation($id: String, $limitName: String) {
      testRecipe (id: $id, limitName: $limitName) {
        isOk
        tonicGrams
        errMsg
      }
    }`,
  };
};

export const recipesTestChoices = () => {
  return {
    query: `query {
      choices {
        affcoLimit {
          name
        }
        specialNeeds {
          name
        }
      }
    }`,
  };
};

export const recipesQuery = () => {
  return {
    query: `query {
      recipes(fetchAll:true) {
        id
        updatedAt
        createdAt
        isDeleted
        isPublished
        displayOrder
        name
        tags
        applySpecies
        bom {
          food {
            id
            name
            originalName
          }
          grams
        }
        recipeCategory
        coverImage {
          id
          url
        }
        images {
          id
          url
        }
        description
        isRecommend
        monthlyCost
      }
    }`,
  };
};

export const foodsQuery = () => {
  return {
    query: `query {
      foods(fetchAll:true) {
        id
        originalName
        costPrice
        updatedAt
        createdAt
        isDeleted
        isPublished
        displayOrder
        priceEnergyRatio
        energyPer100g
        name
        goodsId
        skus {
          id
          foodId
          skuId
          package
          stock
          price
        }
        category
        price
        a0
        b0
        c0
        d0
        e0
        f0
        g0
        h0
        i0
        j0
        k0
        l0
        m0
        n0
        o0
        p0
        q0
        r0
        s0
        t0
        u0
        v0
        w0
        x0
        y0
        z0
        a1
        b2
        c2
        d2
        e2
        f2
        g2
        h2
        i2
        j2
        k2
        o2
        p2
        r2
        s2
        t2
        u2
        v2
        w2
        x2
        y2
        z2
        a3
        b3
      }
    }`,
  };
};

export const updateFoodQuery = () => {
  return {
    query: `mutation(
      $objectId: String!
      $a0: String
      $a1: String
      $a3: String
      $b0: String
      $b2: String
      $c0: String
      $c2: String
      $category: String
      $costPrice: Decimal
      $d0: String
      $d2: String
      $e0: String
      $e2: String
      $f0: String
      $f2: String
      $g0: String
      $g2: String
      $h0: String
      $h2: String
      $i0: String
      $i2: String
      $j0: String
      $j2: String
      $k0: String
      $k2: String
      $l0: String
      $m0: String
      $n0: String
      $name: String
      $o0: String
      $o2: String
      $originalName: String
      $p0: String
      $p2: String
      $price: Decimal
      $q0: String
      $r0: String
      $r2: String
      $s0: String
      $s2: String
      $t0: String
      $t2: String
      $td4: String
      $u0: String
      $u2: String
      $v0: String
      $v2: String
      $w0: String
      $w2: String
      $x0: String
      $x2: String
      $y0: String
      $y2: String
      $z0: String
      $z2: String
      $goodsId: String
    ) {
      updateFood(
        objectId: $objectId
        goodsId: $goodsId
        a0: $a0
        a1: $a1
        a3: $a3
        b0: $b0
        b2: $b2
        c0: $c0
        c2: $c2
        category: $category
        costPrice: $costPrice
        d0: $d0
        d2: $d2
        e0: $e0
        e2: $e2
        f0: $f0
        f2: $f2
        g0: $g0
        g2: $g2
        h0: $h0
        h2: $h2
        i0: $i0
        i2: $i2
        j0: $j0
        j2: $j2
        k0: $k0
        k2: $k2
        l0: $l0
        m0: $m0
        n0: $n0
        name: $name
        o0: $o0
        o2: $o2
        originalName: $originalName
        p0: $p0
        p2: $p2
        price: $price
        q0: $q0
        r0: $r0
        r2: $r2
        s0: $s0
        s2: $s2
        t0: $t0
        t2: $t2
        td4: $td4
        u0: $u0
        u2: $u2
        v0: $v0
        v2: $v2
        w0: $w0
        w2: $w2
        x0: $x0
        x2: $x2
        y0: $y0
        y2: $y2
        z0: $z0
        z2: $z2
      ) {
        obj {
          id
        }
      }
    }`,
  };
};

export const editRecipeQuery = () => {
  return {
    query: `mutation(
      $applySpecies: [PetSpecie]
      $bom: [BomInput]
      $coverImage: String
      $description: String
      $displayOrder: Int
      $images: [String]
      $isDeleted: Boolean
      $isPublished: Boolean
      $isRecommend: Boolean
      $name: String
      $id: String
      $recipeCategory: RecipeCategory
      $tags: [String]
    ) {
      editRecipe(
        applySpecies: $applySpecies
        bom: $bom
        coverImage: $coverImage
        description: $description
        displayOrder: $displayOrder
        images: $images
        isDeleted: $isDeleted
        isPublished: $isPublished
        isRecommend: $isRecommend
        name: $name
        id: $id
        recipeCategory: $recipeCategory
        tags: $tags
      ) {
          obj {
            id
          }
      }
    }`,
  };
};

export const petUsersQuery = () => {
  return {
    query: `query($limit: Int = 999, $offset: Int = 0) {
      petUsers(limit: $limit, offset: $offset) {
        id
        unionId
        nickname
        avatarUrl
        phoneNum
        pets {
          id
          name
          avatar {url}
          gender
          birthday
          isNeutered
          weight
          breed
          specie
          bodyShape
        }
      }
    }`,
  };
};

export const testRecipeQuery = () => {
  return {
    query: `mutation(
      $id: String
    ) {
      testRecipe(id: $id) {
        isOk
      }
    }`,
  };
};

export const delFoodQuery = () => {
  return {
    query: `mutation($objectId: String!) {
      updateFood(isDeleted: true, objectId: $objectId) {
        obj {
          id
        }
      }
    }`,
  };
};

export const createFoodQuery = () => {
  return {
    query: `mutation(
      $a0: String
      $a1: String
      $a3: String
      $b0: String
      $b2: String
      $c0: String
      $c2: String
      $category: String
      $costPrice: Decimal
      $d0: String
      $d2: String
      $e0: String
      $e2: String
      $f0: String
      $f2: String
      $g0: String
      $g2: String
      $h0: String
      $h2: String
      $i0: String
      $i2: String
      $j0: String
      $j2: String
      $k0: String
      $k2: String
      $l0: String
      $m0: String
      $n0: String
      $name: String!
      $o0: String
      $o2: String
      $originalName: String
      $p0: String
      $p2: String
      $price: Decimal
      $q0: String
      $r0: String
      $r2: String
      $s0: String
      $s2: String
      $t0: String
      $t2: String
      $td4: String
      $u0: String
      $u2: String
      $v0: String
      $v2: String
      $w0: String
      $w2: String
      $x0: String
      $x2: String
      $y0: String
      $y2: String
      $z0: String
      $z2: String
      $goodsId: String
    ) {
      createFood(
        goodsId: $goodsId
        a0: $a0
        a1: $a1
        a3: $a3
        b0: $b0
        b2: $b2
        c0: $c0
        c2: $c2
        category: $category
        costPrice: $costPrice
        d0: $d0
        d2: $d2
        e0: $e0
        e2: $e2
        f0: $f0
        f2: $f2
        g0: $g0
        g2: $g2
        h0: $h0
        h2: $h2
        i0: $i0
        i2: $i2
        j0: $j0
        j2: $j2
        k0: $k0
        k2: $k2
        l0: $l0
        m0: $m0
        n0: $n0
        name: $name
        o0: $o0
        o2: $o2
        originalName: $originalName
        p0: $p0
        p2: $p2
        price: $price
        q0: $q0
        r0: $r0
        r2: $r2
        s0: $s0
        s2: $s2
        t0: $t0
        t2: $t2
        td4: $td4
        u0: $u0
        u2: $u2
        v0: $v0
        v2: $v2
        w0: $w0
        w2: $w2
        x0: $x0
        x2: $x2
        y0: $y0
        y2: $y2
        z0: $z0
        z2: $z2
      ) {
        obj {
          id
        }
      }
    }`,
  };
};
